import React from 'react'
import { upskillSectionThreeContent } from '../../data/upskill'

export const UpskillSectionThree = () => {
  return (
      <section className='bg-black relative'>
          <div className='lg:w-2/3 mx-auto lg:text-center py-14 text-white px-6 lg:px-0'>
              <h1 className='text-[42px] font-[700] '><span className='text-[#985CF1]'>{upskillSectionThreeContent.title.coloured}</span>{upskillSectionThreeContent.title.normal}</h1>
              <p className='mt-4 font-[400] leading-[30px] text-[16px]'>{upskillSectionThreeContent.paraLine}</p>
              <div className='flex flex-col md:flex-row md:space-x-6 md:py-14'>
                  {
                      upskillSectionThreeContent.listData.map((data, index) => {
                          return (
                              <div key={index} className="py-6 md:py-0">
                                  <h4 className='font-[700] text-center lg:text-start text-[24px]'>{data.num}</h4>
                                  <p className='font-[400] text-center lg:text-start text-[14px] mt-4'>{data.text}</p>
                              </div>
                          )
                      })
                  }
              </div>
              <p className='font-[400] text-[16px] leading-[30px]'>{upskillSectionThreeContent.paraLineTwo}</p>
          </div>
          <img src={upskillSectionThreeContent.image} alt="upskillImage" className='relative mt-[-100px] w-full lg:mt-[-200px] 2xl:mt-[-300px] object-cover'/>
    </section>
  )
}
