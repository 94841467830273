import React from "react";
import { Layout } from "../components/Layout";
import { UpSkillPage } from "../components/Upskill/UpSkillPage";
import "../components/index.css";

const UpSkill = () => {
    return (
        <Layout>
            <UpSkillPage />
        </Layout>
    )
}

export default UpSkill;