import React from 'react'
import { UpskillBanner } from './UpskillBanner'
import { UpskillSectionFour } from './UpskillSectionFour'
import { UpskillSectionThree } from './UpskillSectionThree'
import { UpskillSectionTwo } from './UpskillSectionTwo'

export const UpSkillPage = () => {
  return (
      <div>
          <UpskillBanner />
          <UpskillSectionTwo />
          <UpskillSectionThree />
          <UpskillSectionFour />
    </div>
  )
}
